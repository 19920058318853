export const KNOWLEDGE_AREA_MAPPER = {
  "1.1": "Gefahrenlehre",
  "1.2": "Verhalten im Straßenverkehr",
  "1.3": "Vorfahrt/Vorrang",
  "1.4": "Verkehrszeichen",
  "1.5": "Umweltschutz",
  "2.1": "klassenspezifisch",
  "2.2": "klassenspezifisch",
  "2.3": "klassenspezifisch",
  "2.4": "klassenspezifisch",
  "2.5": "klassenspezifisch",
  "2.6": "klassenspezifisch",
  "2.7": "klassenspezifisch",
  "2.8": "klassenspezifisch",
};
