
import Video from "@/directives/Video";
import useFile from "@/composable/useFile";
import { IQuestion } from "@/interfaces/IQuestion";
import { computed, defineComponent, PropType, ref } from "vue";
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/vue";
import { StatusBar } from "@capacitor/status-bar";
//@ts-ignore
import { HomeIndicator } from "@capgo/home-indicator";
import { KeepAwake } from "@capacitor-community/keep-awake";
import { Capacitor } from "@capacitor/core";
import { AndroidFullScreen, AndroidSystemUiFlags } from "@awesome-cordova-plugins/android-full-screen";

export default defineComponent({
  name: "VideoPlayer",
  directives: {
    Video: new Video(),
  },
  components: {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
  },
  props: {
    mediaUrl: {
      type: String,
    },
    posterUrl: {
      type: String,
    },
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
  },
  emits: ["hide", "load", "error", "ended"],
  setup(props, { emit }) {
    const videoPlayer = ref();
    const fileSystem = useFile();
    const showModal = ref(false);
    const videoIsPaused = ref(false);

    const videoURL = computed(() => {
      return fileSystem.getMediaFromFilesystem(props.question, props.mediaUrl, false);
    });

    const posterURL = computed(() => {
      return fileSystem.getMediaFromFilesystem(props.question, props.posterUrl, false);
    });

    const onLoad = (event: any) => {
      emit("load", event);
    };

    const onError = (event: any) => {
      emit("error", event);
    };

    const onEnded = (event: any) => {
      emit("ended", event);
    };

    const show = () => {
      showModal.value = true;
    };

    const hide = () => {
      showModal.value = false;
    };

    const onClose = () => {
      hide();
    };

    const hideStatusBar = async () => {
      await StatusBar.hide();
    };

    const showStatusBar = async () => {
      await StatusBar.show();
    };

    const keepAwake = async () => {
      await KeepAwake.keepAwake();
    };

    const allowSleep = async () => {
      await KeepAwake.allowSleep();
    };
    const openNavigationBar = () => {
      HomeIndicator.show();
    };

    const hideNavigationBar = () => {
      HomeIndicator.hide();
    };

    const isNativeAndroid = computed(() => {
      return Capacitor.getPlatform() == "android";
    });

    const didPresent = () => {
      if (isNativeAndroid.value) {
        AndroidFullScreen.isImmersiveModeSupported()
          .then(() => AndroidFullScreen.immersiveMode())
          .catch(console.warn);
      } else {
        hideNavigationBar();
        hideStatusBar();
      }
      keepAwake();
    };

    const didDismiss = () => {
      if (isNativeAndroid.value) {
        AndroidFullScreen.isImmersiveModeSupported()
          .then(() => AndroidFullScreen.setSystemUiVisibility(AndroidSystemUiFlags.Fullscreen))
          .catch(console.warn);
      } else {
        openNavigationBar();
        showStatusBar();
      }
      allowSleep();
      emit("hide");
    };

    const toggleVideoPlaying = () => {
      if (videoPlayer.value.paused) {
        videoPlayer.value.play();
        videoIsPaused.value = false;
      } else {
        videoPlayer.value.pause();
        videoIsPaused.value = true;
      }
    };

    return {
      onClose,
      show,
      hide,
      videoPlayer,
      onLoad,
      onError,
      onEnded,
      posterURL,
      videoURL,
      showModal,
      didDismiss,
      didPresent,
      toggleVideoPlaying,
      videoIsPaused,
    };
  },
});
