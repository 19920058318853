import { mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_image = _resolveDirective("image")!

  return _withDirectives((_openBlock(), _createElementBlock("img", _mergeProps(_ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    alt: ""
  }), null, 16)), [
    [_directive_image, {
      url: _ctx.mediaURL,
      auth: false,
      loading: _ctx.onLoading,
      success: _ctx.onLoaded,
      fail: _ctx.onError,
    }]
  ])
}