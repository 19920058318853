import { Options, Vue } from "vue-property-decorator";

@Options({})
export default class ImageLoadingMixin extends Vue {
  protected imgLoading = true;
  protected imgLoadError = false;

  private onImageLoaded(e: Event): void {
    this.imgLoading = false;
  }

  private onImageLoadError(e: Event): void {
    this.imgLoading = false;
    this.imgLoadError = true;
  }

  private onImageLoading(e: Event): void {
    this.imgLoading = true;
  }
}
