import { Options, Vue } from "vue-property-decorator";

@Options({})
export default class VideoLoadingMixin extends Vue {
  protected videoLoading = true;
  protected videoLoadError = false;

  private onVideoLoaded(e: Event): void {
    this.videoLoading = false;
  }

  private onVideoLoadError(e: Event): void {
    this.videoLoading = false;
    this.videoLoadError = true;
  }
}
