import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class QuestionMixin extends Vue {
  @Prop()
  public question!: any;
  @Prop()
  public showCorrectAnswers!: any;

  protected showSolutionButton(): void {
    this.$emit("onShowSolutionButton");
  }

  // TODO maybe separate it
  // since showSolutionButton is for theory
  // onAnswerGiven for practice
  protected answerGiven(given: any): void {
    this.$emit("onAnswerGiven", given);
  }
}
